import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AuthTemplate from "../../templates/Auth";
import api from "../../services/api";

import Input from "../../components/Input";
import InputPassword from "../../components/InputPassword";
import Button from "../../components/Button";
import { logout, setToken, setUser } from "../../services/auth";
import { renderError } from "../../helpers/errors";
import { getInitialPath } from "../../routes/privateRoutes";

export const PasswordRecovery = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');

    useEffect(() => {
        logout();
    }, []);

    const signIn = () => {
        
    }

    return (
        <AuthTemplate>
            <img id="logo" src={ require(`../../assets/images/svgs/logo.svg`).default} />
            <h1>Recuperação de senha</h1>
            <p>Um e-mail será enviado com um token.</p>
            <Input type={`email`} label={`E-mail`} change={setEmail} value={email} action={signIn} />
            <Button type={`primary`} text={`Enviar e-mail`} full={true} svg={`arrow-right-white`} loading={loading} action={signIn} />
        </AuthTemplate>
    );
}

export default PasswordRecovery;