import React from "react";
import InputCheckbox from "../InputCheckbox";

export const InputCheckboxUnique = ({checked, label, change}) => {
    return (
        <button
            className="input-checkbox-unique"
            onClick={() => change(!checked)}
        >
            <InputCheckbox active={checked} />
            {label}
        </button>
    );
}

export default InputCheckboxUnique;