import moment from "moment";
import React, { useState, useEffect } from "react";
import Button from "../Button";
import InputDate from "../InputDate";
import Modal from "../Modal";
import { getToken } from "../../services/auth";

export const ModalExport = ({showModal, setShowModal, url}) => {
    const [initDate, setInitDate] = useState(null);
    const [finalDate, setFinalDate] = useState(null);

    const exportExcel = () => {
        let diff = moment(finalDate).diff(moment(initDate), 'days') + 1;
        if(isNaN(diff) || diff < 0){
            alert('Informe corretamente as datas');
            return;
        }
        if(diff > 90){
            alert('Você informou um período maior que o permitido');
            return;
        }

        let params = `?start_date=${moment(initDate).format('YYYY-MM-DD')}`;
        params += `&final_date=${moment(finalDate).format('YYYY-MM-DD')}`;
        params += `&_token=${getToken()}`;

        window.open(url + params);
    }
    
    return (
        <Modal
            show={showModal}
            close={() => setShowModal(false)}
            title={`Exportação`}
            footer={
                <>
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Exportar`}
                        action={exportExcel}
                    />
                    
                </>
            }
        >
            <div className="section">
                <p>Informe um período máximo de 90 dias</p>
                <br />
                <div className="row">
                    <div className="col-6">
                        <InputDate
                            value={initDate}
                            change={setInitDate}
                            label={`Data inicial`}
                        />
                    </div>
                    <div className="col-6">
                        <InputDate
                            value={finalDate}
                            change={setFinalDate}
                            label={`Data final`}
                        />
                    </div>
                </div>
            </div>
        </Modal>
    )
}