import React, { useEffect, useState } from "react";
import Select from 'react-select';
import moment from 'moment';
import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import InputColor from "../../components/InputColor";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Input from "../../components/Input";
import InputSelect from "../../components/InputSelect";
import InputFile from "../../components/InputFile";
import { toast } from "react-toastify";
import DotColor from "../../components/DotColor";
import { ModalDelete } from "../../components/ModalDelete";
import BoxTabs from "../../components/BoxTabs";

const defaultOptions = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
];

const typeOptions = [
    { label: 'Pedido', value: 'order' },
    { label: 'Orçamento', value: 'budget' }
];


export const StatusList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [statuses, setStatuses] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateStatus, setShowCreateStatus] = useState(false);
    const [showEditStatus, setShowEditStatus] = useState(false);

    const [statusId, setStatusId] = useState(null);
    const [statusName, setStatusName] = useState('');
    const [statusType, setStatusType] = useState('budget');
    const [statusDefault, setStatusDefault] = useState(false);
    const [statusColor, setStatusColor] = useState('');

    const [statusToDelete, setStatusToDelete] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getStatuses();
    }, [filters]);

    useEffect(() => {
        if(!showCreateStatus) clean();
    }, [showCreateStatus]);

    useEffect(() => {
        if(!showEditStatus) clean();
    }, [showEditStatus]);

    useEffect(() => {
        generateData();
    }, [statuses]);

    const generateData = () => {
        const header = ['Nome', 'Cor', 'Padrão',  'Última atualização', 'Cadastrado em'];
        const rows = {};
        
        typeOptions.map(type => {
            rows[type.value] = [];
            statuses.map(status => {
                if(status.type !== type.value) return;

                rows[type.value].push({
                    data: [
                        status.name,
                        <DotStatus color={status.color} size={15} />,
                        status.default ? 'Sim' : 'Não',
                        moment(status.updated_at).format('DD/MM/YYYY HH:mm'),
                        moment(status.created_at).format('DD/MM/YYYY HH:mm')
                    ],
                    action: () => editStatus(status)
                })
            });
        })

        setData({header, rows});
    }

    const editStatus = status => {
        setStatusId(status.id);
        setStatusName(status.name);
        setStatusType(status.type);
        setStatusDefault(status.default);
        setStatusColor(status.color);
        setShowEditStatus(true);
    }

    const getStatuses = () => {
        setStatuses([]);
        setLoading(true);

        api.get(`/status${filters}`).then(res => {
            setStatuses(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const storeStatus = () => {
        setLoading(true);

        let data = {
            name: statusName,
            type: statusType,
            default: statusDefault,
            color: statusColor
        };

        api.post(`/status`, data).then(res => {
            toast.success('Status cadastrado com sucesso');
            setShowCreateStatus(false);
            getStatuses();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const updateStatus = () => {
        setLoading(true);

        let data = {
            name: statusName,
            type: statusType,
            default: statusDefault,
            color: statusColor
        };

        api.put(`/status/${statusId}`, data).then(res => {
            toast.success('Status atualizado com sucesso');
            setShowEditStatus(false);
            getStatuses();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setStatusId('');
        setStatusName('');
        setStatusType('budget');
        setStatusDefault(false);
        setStatusColor('');
    }

    const deleteStatus = () => {
        setLoading(true);
    
        api.delete(`/status/${statusToDelete}`).then(res => {
            setStatusToDelete(null);
            setShowEditStatus(false);
            getStatuses();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    return (
        <>
            
            <Modal
                title={`Cadastrar status`}
                show={showCreateStatus}
                close={() => setShowCreateStatus(false)}
                footer={
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Cadastrar`}
                        svg={`save-white`}
                        action={storeStatus}
                        loading={loading} 
                    />
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome *`} value={statusName} change={setStatusName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo *`} value={statusType} change={setStatusType} options={typeOptions} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <InputColor label={`Cor *`} value={statusColor} change={setStatusColor} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Padrão *`} value={statusDefault} change={setStatusDefault} options={defaultOptions} />
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal
                title={`Editar status`}
                show={showEditStatus}
                close={() => setShowEditStatus(false)}
                footer={
                    <>
                        <Button
                            type={`secondary`}
                            size={`small`}
                            text={`Excluir`}
                            svg={`delete-small`}
                            action={() => setStatusToDelete(statusId)}
                            loading={loading} 
                        />
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateStatus}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-6">
                            <Input type={`text`} label={`Nome *`} value={statusName} change={setStatusName} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Tipo *`} value={statusType} change={setStatusType} options={typeOptions} />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <InputColor label={`Cor *`} value={statusColor} change={setStatusColor} />
                        </div>
                        <div className="col-6">
                            <InputSelect label={`Padrão *`} value={statusDefault} change={setStatusDefault} options={defaultOptions} />
                        </div>
                    </div>
                </div>
            </Modal>
            <ModalDelete
                show={statusToDelete !== null}
                close={() => setStatusToDelete(null)}
                action={deleteStatus}
                loading={loading}
            />
            <PanelTemplate id="status-list">
                <PageHeader
                    // left={
                    //     <FilterSimple 
                    //         params={[
                    //             {name: 'Nome', key: 'name', type: 'text' },
                    //         ]}
                    //         filter={setFilters}
                    //     />
                    // }

                    right={
                        <>
                            <Button type={`primary`} size={`small`} text={`Cadastrar status`} svg="plus-white" action={() => setShowCreateStatus(true) }  />
                        </>
                    }
                />
                <BoxTabs
                    titles={[
                        'Orçamento',
                        'Pedido'
                    ]}
                    contents={[
                        <Table header={data.header} rows={data.rows.budget} loading={loading} />,
                        <Table header={data.header} rows={data.rows.order} loading={loading} />
                    ]}
                />
            </PanelTemplate>
        </>
    );
}

export default StatusList;