import { hasPermission } from "../services/auth";

import Dashboard from '../pages/Dashboard';
import ProductList from '../pages/Product/list';
import ProductEdit from '../pages/Product/edit';
import ProductCreate from '../pages/Product/create';
import ColorList from '../pages/Color/list';
import CategoryList from '../pages/Category/list';
import TagList from '../pages/Tag/list';
import CustomizationList from '../pages/Customization/list';
import CustomizationEdit from '../pages/Customization/edit';
import CompanyList from '../pages/Company/list';
import CompanyEdit from '../pages/Company/edit';
import CustomerList from '../pages/Customer/list';
import QuoteList from '../pages/Quote/list';
import QuoteEdit from '../pages/Quote/edit';
import OrderCreate from '../pages/Order/craete';
import OrderList from '../pages/Order/list';
import OrderEdit from '../pages/Order/edit';
import UserList from '../pages/User/list';
import Proposal from '../pages/Proposal';
import PageList from '../pages/Page';
import PageEdit from '../pages/Page/edit';
import BannerList from '../pages/Banner';
import LogoList from '../pages/Logo';
import TestimonialList from '../pages/Testimonial';
import Calculation from '../pages/Calculation';
import EmailTemplateEdit from '../pages/EmailTemplate/edit';
import EmailTemplateList from '../pages/EmailTemplate';
import { CustomerEdit } from '../pages/Customer/edit';
import StatusList from '../pages/Status/list';
import RoleList from '../pages/Role/list';
import VariableList, { Site } from "../pages/Site/list";
import OrderPdf from "../pages/OrderPdf";


export const privateRoutes = [
    {
        path: "/dashboard",
        element: <Dashboard />,
        show: hasPermission(['view_unrelated_dashboard', 'view_related_dashboard']),
        main: true
    },
    {
        path: "/product/list",
        element: <ProductList />,
        show: hasPermission('view_product'),
        main: true
    },
    {
        path: "/product/edit/:id",
        element: <ProductEdit />,
        show: true, // para sempre ser possível visualizar
        main: false
    },
    {
        path: "/product/create",
        element: <ProductCreate />,
        show: hasPermission('create_product'),
        main: false
    },
    {
        path: "/color/list",
        element: <ColorList />,
        show: hasPermission('view_color'),
        main: true
    },
    {
        path: "/category/list",
        element: <CategoryList />,
        show: hasPermission('view_category'),
        main: true
    },
    {
        path: "/tag/list",
        element: <TagList />,
        show: hasPermission('view_tag'),
        main: true
    },
    {
        path: "/customization/list",
        element: <CustomizationList />,
        show: hasPermission('view_customization'),
        main: true
    },
    {
        path: "/customization/edit/:id",
        element: <CustomizationEdit />,
        show: hasPermission('edit_customization'),
        main: false
    },
    {
        path: "/company/list",
        element: <CompanyList />,
        show: true,
        main: true
    },
    {
        path: "/company/edit/:id",
        element: <CompanyEdit />,
        show: true,
        main: false
    },
    {
        path: "/customer/list",
        element: <CustomerList />,
        show: hasPermission(['view_unrelated_customer', 'view_related_customer']),
        main: true
    },
    {
        path: "/customer/edit/:id",
        element: <CustomerEdit />,
        show: true,
        main: false
    },
    {
        path: "/quote/list",
        element: <QuoteList />,
        show: true,
        main: true
    },
    {
        path: "/quote/edit/:id",
        element: <QuoteEdit />,
        show: true,
        main: false
    },
    {
        path: "/proposal/:id",
        element: <Proposal />,
        show: true,
        main: false
    },
    {
        path: "/order/pdf/:id",
        element: <OrderPdf />,
        show: true,
        main: false
    },
    {
        path: "/order/list",
        element: <OrderList />,
        show: true,
        main: true
    },
    {
        path: "/order/create/:id",
        element: <OrderCreate />,
        show: true,
        main: false
    },
    {
        path: "/order/edit/:id",
        element: <OrderEdit />,
        show: true,
        main: false
    },
    {
        path: "/user/list",
        element: <UserList />,
        show: true,
        main: true
    },
    {
        path: "/role/list",
        element: <RoleList />,
        show: true,
        main: true
    },
    {
        path: "/page/list",
        element: <PageList />,
        show: true,
        main: true
    },
    {
        path: "/page/edit/:id",
        element: <PageEdit />,
        show: true,
        main: false
    },
    {
        path: "/email-template/list",
        element: <EmailTemplateList />,
        show: true,
        main: true
    },
    {
        path: "/email-template/edit/:id",
        element: <EmailTemplateEdit />,
        show: true,
        main: false
    },
    {
        path: "/banner/list",
        element: <BannerList />,
        show: true,
        main: true
    },
    {
        path: "/logo/list",
        element: <LogoList />,
        show: true,
        main: true
    },
    {
        path: "/testimonial/list",
        element: <TestimonialList />,
        show: true,
        main: true
    },
    {
        path: "/calculation",
        element: <Calculation />,
        show: true,
        main: true
    },
    {
        path: "/status/list",
        element: <StatusList />,
        show: hasPermission('status'),
        main: true
    },
    {
        path: "/variable/list",
        element: <Site />,
        show: hasPermission('site'),
        main: true
    },
];

export const getInitialPath = () => {
    let path = null;

    for(let i = 0; i < privateRoutes.length; i++){
        console.log(privateRoutes, path = privateRoutes[i].path, privateRoutes[i].show && privateRoutes[i].main)
        if(privateRoutes[i].show && privateRoutes[i].main){
            path = privateRoutes[i].path;
            break;
        }
    }
    
    return path;
}

export default privateRoutes;