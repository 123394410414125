import React, { useEffect, useState } from "react";
import moment from 'moment';
import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import Input from "../../components/Input";
import { toast } from "react-toastify";
import InputTextarea from "../../components/InputTextarea";

const defaultOptions = [
    {label: 'Sim', value: true},
    {label: 'Não', value: false}
];

const typeOptions = [
    { label: 'Pedido', value: 'order' },
    { label: 'Orçamento', value: 'budget' }
];


export const Site = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [variables, setVariables] = useState([]);
    const [data, setData] = useState({header: [], rows: []});

    const [showCreateVariable, setShowCreateVariable] = useState(false);
    const [showEditVariable, setShowEditVariable] = useState(false);

    const [variableId, setVariableId] = useState(null);
    const [variableValue, setVariableValue] = useState('');

    const [variableToDelete, setVariableToDelete] = useState(null);

    const navigate = useNavigate();

    useEffect(() => {
        getVariables();
    }, [filters]);

    useEffect(() => {
        if(!showCreateVariable) clean();
    }, [showCreateVariable]);

    useEffect(() => {
        if(!showEditVariable) clean();
    }, [showEditVariable]);

    useEffect(() => {
        generateData();
    }, [variables]);

    const generateData = () => {
        const header = ['Nome', 'Valor', 'Última atualização'];
        const rows = [];
        
        variables.map(variable => {

            rows.push({
                data: [
                    variable.label,
                    variable.value,
                    moment(variable.updated_at).format('DD/MM/YYYY HH:mm'),
                ],
                action: () => editVariable(variable)
            })
        })

        setData({header, rows});
    }

    const editVariable = variable => {
        setVariableId(variable.id);
        setVariableValue(variable.value);
        setShowEditVariable(true);
    }

    const getVariables = () => {
        setVariables([]);
        setLoading(true);

        api.get(`/variable?${filters}`).then(res => {
            setVariables(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const updateVariable = () => {
        setLoading(true);

        let data = {
            value: variableValue
        };

        api.put(`/variable/${variableId}`, data).then(res => {
            toast.success('Atualização realizada com sucesso');
            setShowEditVariable(false);
            getVariables();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const clean = () => {
        setVariableId('');
        setVariableValue('');
    }

    return (
        <>
            <Modal
                title={`Editar variável`}
                show={showEditVariable}
                close={() => setShowEditVariable(false)}
                footer={
                    <>
                        <Button
                            type={`primary`}
                            size={`small`}
                            text={`Salvar alterações`}
                            svg={`save-white`}
                            action={updateVariable}
                            loading={loading} 
                        />
                    </>
                }
            >
                <div className="section">
                    <div className="row">
                        <div className="col-12">
                            <InputTextarea type={`text`} label={`Valor`} value={variableValue} change={setVariableValue} />
                        </div>
                    </div>
                </div>
            </Modal>
            <PanelTemplate id="variable-list">
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome', key: '[label]', type: 'text' },
                            ]}
                            filter={setFilters}
                        />
                    }
                />
                <Box className={`no-padding`}>
                    <Table header={data.header} rows={data.rows} loading={loading} />
                </Box>
            </PanelTemplate>
        </>
    );
}

export default Site;