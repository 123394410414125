import DotStatus from "../components/DotStatus";

export const createOptions = (array, label = null, value = null, nullable = false, valueToExclude = null) => {
    let options = [];

    if(nullable){
        options.push({
            value: null,
            label: 'Nenhuma opção'
        });
    }

    const getLabelText = item => {
        let labelText = '';

        if(label === null){
            labelText = item;
        } else if(Array.isArray(label)){
            let labels = [];
            label.map(l => {
                labels.push(item[l]);
            });
            labelText = labels.join(' - ');
        } else {
            labelText = item[label];
        }

        return labelText;
    }

    array.map(item => {
        let hasItem = options.filter(o => o.value === item[value]).length === 0 ? false : true;
        if(!hasItem){
            options.push({
                label: getLabelText(item),
                value: value === null ? item : item[value]
            })
        }
    });

    if(valueToExclude !== null){
        options = options.filter(option => option.value !== valueToExclude)
    }

    return options;
}

export const createStatusOptions = (statuses, nullable = true) => {
    let options = [];

    // if(nullable){
    //     options.push({
    //         value: null,
    //         label: 'Nenhuma opção'
    //     });
    // }

    statuses.map(status => {
        options.push({
            label: <span><DotStatus color={status?.color} size={15} />{status?.name}</span>,
            value: status.id
        })
    });

    return options;
}

export const createProductOptions = (array, label = null, value = null, nullable = false) => {
    let options = [];

    if(nullable){
        options.push({
            value: null,
            label: 'Nenhuma opção'
        });
    }

    array.map(item => {
        let hasItem = options.filter(o => o.value === item[value]).length === 0 ? false : true;
        if(!hasItem){
            let labelImage = item.image ? <img src={item.image.image_url.small} /> :<img src={ require(`../assets/images/svgs/default.svg`).default } />;
            let labelText = item.sku + ' - ' + item.name;
            options.push({
                label: labelText,
                value: value === null ? item : item[value],
                image: labelImage
            })
        }
    });

    return options;
}