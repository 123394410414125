import axios from "axios";
import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createFilterQueryString } from "../../../helpers/helpers";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectCustomerCompany = ({selectedCustomerCompany, setSelectedCustomerCompany, isMulti, changeCompanies}) => {
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const [search, setSearch] = useState('');
    const [noOptionsText, setNoOptionsText] = useState('');

    window.controller = null;

    const minSearch = 1;

    useEffect(() => {
        searchCustomerCompanies();
    }, [search]);
    
    useEffect(() => {
        checkIfNeedToGet();
    }, [selectedCustomerCompany]);

    useEffect(() => {
        if(changeCompanies){
            changeCompanies(customerCompanies);
        }
    }, [customerCompanies]);

    const checkIfNeedToGet = () => {
        let companiesIds = isMulti ? selectedCustomerCompany : [selectedCustomerCompany];
        let term = '';

        companiesIds.map(companyId => {
            if(companyId === null) return;
            if(!customerCompanies.some(customerCompany => customerCompany.id === companyId)){
                term = companyId;
            }
        });

        if(term){
            getCustomerCompanies(term);
        } else {
            // Para forçar atualização da mensagem "Digite mais x caracteres"
            setSearch('');
        }
    }

    const clearOptions = () => {
        handleSetCustomerCompanies([], true)
    }

    const searchCustomerCompanies = () => {
        if(search.length < minSearch) {
            let diff = minSearch - search.length;
            setNoOptionsText(`Digite mais ${diff} ${diff === 1 ? 'caracter' : 'caracteres'}`);
            clearOptions();
            if(window.requestController){
                window.requestController.abort();
            };
            return;
        };

        getCustomerCompanies(search);        
    }

    const getCustomerCompanies = term => {
        if(window.requestController){
            window.requestController.abort();
        };

        window.requestController = new AbortController();
        
        handleSetCustomerCompanies([]);
        setNoOptionsText('Carregando...');

        api.get(`/company/search?type=customer&term=${term}`, {
            signal: window.requestController.signal
        }).then(res => {
            handleSetCustomerCompanies(res.data.resources.data);
        }).catch(error => {
            if(error.code !== undefined){
                renderError(error);
            }
        });
    }

    const handleSetCustomerCompanies = (resources, fromClear = false) => {
        let currentSelectedCustomerCompanies = getSelectedCustomerCompanies();

        let toCustomerCompanies = resources.filter(resource => {
            return !currentSelectedCustomerCompanies.some(current => current.id === resource.id);
        });

        setCustomerCompanies([...currentSelectedCustomerCompanies, ...toCustomerCompanies]);
        if(!fromClear){
            setNoOptionsText(toCustomerCompanies.length === 0 ? 'Nenhum resultado encontrado' : '');
        }
    }

    const getSelectedCustomerCompanies = () => {
        return customerCompanies.filter(customerCompany => {
            if(isMulti){
                return selectedCustomerCompany.includes(customerCompany.id);
            } else {
                return selectedCustomerCompany === customerCompany.id;
            }
        });
    }

    return (
        <InputSelect
            noOptionsText={noOptionsText}
            label={`Empresa`}
            options={createOptions(customerCompanies, ['code', 'trade_name'], 'id')} 
            value={selectedCustomerCompany} 
            change={setSelectedCustomerCompany}
            isMulti={isMulti === true}
            setSearch={value => setSearch(value)}
        />
    );
}

export default SelectCustomerCompany;