import React, { useState, useEffect } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import ModalCreateQuote from "./ModalCreateQuote";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import { convertIntToMoney } from "../../helpers/conversions";
import { ModalDelete } from "../../components/ModalDelete";
import { hasPermission, hasQuoteDeletePermission } from "../../services/auth";
import { ModalFilter } from "./ModalFilter";
import Pagination from "../../components/Pagination";
import InputCheckbox from "../../components/InputCheckbox";
import ButtonCheckbox from "../../components/ButtonCheckbox";
import { toast } from "react-toastify";
import SelectStatus from "../../components/selects/SelectStatus";
import { createStatusOptions } from "../../helpers/selects";
import InputSelect from "../../components/InputSelect";
import { ModalExport } from "../../components/ModalExport";

const SelectStatusList = ({quote, statuses}) => {
    const [loading, setLoading] = useState(false);
    const [currentStatusId, setCurrentStatusId] = useState(quote.status.id);

    const updateQuoteStatus = (quoteId, statusId) => {
        setLoading(true);

        api.put(`/budget/${quoteId}/status`, {
            status_id: statusId
        }).then(res => {
            setCurrentStatusId(statusId);
            toast.success('Status atualizado');
        }).catch(error => {
            renderError(error);
        }).then(() => {
            setLoading(false);
        });
    }

    return (
        <div className="select-status-list">
            <InputSelect
                loading={loading}
                label={`Status`}
                options={createStatusOptions(statuses)} 
                value={currentStatusId} 
                change={statusId => updateQuoteStatus(quote.id, statusId)}
            />
        </div>
    );
}

export const QuoteList = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(true);
    const [showExport, setShowExport] = useState(false);
    const [showFilter, setShowFilter] = useState(false);
    const [filters, setFilters] = useState('?');
    const [customFilters, setCustomFilters] = useState('');

    const [quotes, setQuotes] = useState([]);
    const [data, setData] = useState({header: [], rows: []});
    const [showCreateQuote, setShowCreateQuote] = useState(false);
    const [quoteToDelete, setQuoteToDelete] = useState(null);

    const [selectedStatus, setSelectedStatus] = useState(null);
    const [statuses, setStatuses] = useState([]);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    useEffect(() => {
        getStatuses();
    }, []);

    useEffect(() => {
        getQuotes();
    }, [currentPage,filters, customFilters, selectedStatus]);

    useEffect(() => {
        generateData();
    }, [quotes]);

    const getStatuses = () => {
        console.log('status');
        api.get(`/status?filters[0][type][equal]=budget`).then(res => {
            setStatuses(res.data.resources);
        });
    }

    const getQuotes = () => {
        setLoading(true);
        let statusFilter = selectedStatus === null ? '' : `&filters[0][related][status.id][equal]=${selectedStatus}`;
        api.get(`/budget/paginate?${filters}${customFilters}${statusFilter}&page=${currentPage}`).then(res => {
            setQuotes(res.data.resources.data);
            setCurrentPage(res.data.resources.current_page);
            setLastPage(res.data.resources.last_page)
            setPerPage(res.data.resources.per_page)
            setTotalResults(res.data.resources.total)
            setHasNextPage(res.data.resources.next_page_url !== null);
            setHasPrevPage(res.data.resources.prev_page_url !== null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const replicateQuote = id => {
        setLoading(true);

        api.post(`/budget/replicate/${id}`).then(res => {
            toast.success('Orçamento duplicado com sucesso');
            getQuotes();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const generateData = () => {
        const header = [
            'Código',
            'Data da solicitação',
            'Nome fantasia',
            'Pessoa',
            'Pedido',
            'Total',
            'Responsável',
            'Origem',
            'Status',
            '',
            ''
        ];
        const rows = [];
        
        quotes.map(quote => {
            let buttons = [];

            buttons.push(
                <SelectStatusList statuses={statuses} quote={quote} />
            );

            buttons.push(<Button type={`delete`} svg={`copy`} action={() => replicateQuote(quote.id)} />);
            
            if(hasQuoteDeletePermission(quote)){
                buttons.push(<Button type={`delete`} svg={`delete`} action={() => setQuoteToDelete(quote.id)} />)
            }

            rows.push({
                data: [
                    quote.code,
                    moment(quote.created_at).format('DD/MM/YYYY HH:mm'),
                    quote?.company?.trade_name,
                    quote?.customer?.name,
                    // <>
                    //     <DotStatus color={quote?.status?.color} size={15} />
                    //     {quote?.status?.name}
                    // </>,
                    quote.order ? <Link target={`_blank`} to={`/order/edit/${quote.order.id}`}>{quote.order.code}</Link> : '',
                    `R$ ${convertIntToMoney(quote.total)}`,
                    quote?.admin?.name,
                    quote?.origin
                ],
                buttons,
                to: `/quote/edit/${quote.id}`,
                id: hasQuoteDeletePermission(quote) ? quote.id : null
            });
        });

        setData({header, rows});
    }

    const finishCreation = () => {
        getQuotes();
    }

    const deleteQuote = () => {
        setLoading(true);

        api.delete(`/budget/${quoteToDelete}`).then(res => {
            getQuotes();
            setQuoteToDelete(null);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const deleteMultipleQuotes = () => {
        setLoading(true);

        api.delete(`/budget/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getQuotes();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }

    const handleSetCustomFilters = value => {
        setCustomFilters(value);
        setCurrentPage(1);
    }

    return (
        <>
            {showCreateQuote &&
                <ModalCreateQuote
                finishCreation={finishCreation}
                    close={() => setShowCreateQuote(false)}
                />
            }
            <ModalExport
                showModal={showExport}
                setShowModal={setShowExport}
                url={`${process.env.REACT_APP_URL}/budget/export`}
            />
            <ModalDelete
                show={quoteToDelete !== null}
                action={deleteQuote}
                loading={loading}
                close={() => setQuoteToDelete(null)}
            />
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'orçamentos' : 'orçamento'}</strong>?</p>}
                action={deleteMultipleQuotes}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <PanelTemplate id="quote-list">
                <ModalFilter
                    setCurrentPage={setCurrentPage}
                    showFilter={showFilter}
                    setShowFilter={setShowFilter}
                    customFilters={customFilters}
                    setCustomFilters={handleSetCustomFilters}
                />
                <PageHeader
                    left={
                        <FilterSimple 
                            params={[
                                {name: 'Nome fantasia', key: '[related][company.trade_name]', operation: 'contain', type: 'text' },
                                {name: 'Razão social', key: '[related][company.company_name]', type: 'text' },
                                {name: 'CNPJ', key: '[related][company.cnpj]', operation: 'contain', type: 'text' },
                                {name: 'Código do orçamento', key: '[code]', operation: 'equal', type: 'text' },
                                {name: 'Código da empresa', key: '[related][company.code]', operation: 'equal', type: 'text' },
                                {name: 'Nome do responsável', key: '[related][admin.name]', operation: 'contain', type: 'text' },
                                {name: 'Nome da pessoa', key: '[related][customer.name]', operation: 'contain', type: 'text' },
                                {name: 'E-mail da pessoa', key: '[related][customer.email]', operation: 'contain', type: 'email' },
                                {name: 'Origem', key: '[origin]', operation: 'contain', type: 'text' }
                            ]}
                            filter={handleSetFilter}
                        />
                    }

                    right={
                        <>
                            {selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('export_budget') &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                text={`Exportar orçamentos`} 
                                action={() => setShowExport(true)}  
                                />
                            }
                            <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter" action={() => setShowFilter(true)} />
                            {hasPermission('create_budget') &&    
                                <Button 
                                    type={`primary`}
                                    size={`small`}
                                    text={`Cadastrar orçamento`}
                                    svg="plus-white"
                                    action={() => setShowCreateQuote(true)}
                                />
                            }
                        </>
                    }
                />
                <ul className="filter-tab">
                    <li>
                        <a href="#" className={`${loading ? 'disabled' : ''} ${selectedStatus === null ? 'active' : ''}`} onClick={e => {
                        e.preventDefault();
                        setCurrentPage(1);
                        setSelectedStatus(null);
                    }}>Todos</a></li>
                    {statuses.map((status, index) => (
                        <li key={index}>
                            <a href="#" className={`${loading ? 'disabled' : ''} ${selectedStatus === status.id ? 'active' : ''}`} onClick={e => {
                                e.preventDefault();
                                setCurrentPage(1);
                                setSelectedStatus(status.id);
                            }}>
                                {status.name}
                            </a>
                        </li>
                    ))}
                </ul>
                <Box className={`no-padding with-pagination with-filter-inline`}>
                    <Table 
                        header={data.header}
                        rows={data.rows}
                        checks={true}
                        loading={loading}
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default QuoteList;