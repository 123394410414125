import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Loading } from "../../components/Loading";
import { renderError } from "../../helpers/errors";
import api from "../../services/api";

export const OrderPdf = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const [loading, setLoading] = useState(true);
    const [order, setOrder] = useState(null);

    useEffect(() => {
        getPdf();
    }, []);

    const getPdf = () => {
        setLoading(true);

        api.get(`/order/pdf/${id}`, {
            responseType: 'blob'
        }).then(res => {
            const file = new Blob([res.data], {type: 'application/pdf'});
            const fileURL = URL.createObjectURL(file);
            const link = document.createElement('a');
            link.href = fileURL;
            link.download = `Pedido.pdf`;
            link.click();
            setTimeout(() => {
                navigate(-1);
            }, 200);
        }).catch(error => {
            console.log(error);
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            <Loading show={true} />
        </>
    );
}

export default OrderPdf;