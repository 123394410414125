import React from "react";
import DatePicker from 'react-date-picker';

export const InputDate = ({label, error, placeholder, type, change, value, disabled, action, autoComplete}) => {    
    const onKeyUp = event => {
        if (event.charCode === 13) {
            if(action !== undefined){
                action();
            }
        }
    }

    return (
        <div className={`input-date${error ? ' has-error' : ''}`}>
            {label &&
                <label>{label}</label>
            }
            <DatePicker 
                format="dd/MM/y"
                onChange={change}
                value={value}
            />
            {error &&
                <span className="error-message">{error}</span>
            }
        </div>
    );
}

export default InputDate;