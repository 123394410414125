import React, { useEffect, useState } from "react";

import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import Box from "../../Box";
import DotStatus from "../../DotStatus";
import { convertIntToMoney } from "../../../helpers/conversions";
import InputDate from "../../InputDate";
import InputCheckboxes from "../../InputCheckboxes";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import Button from "../../Button";
import { toast } from "react-toastify";
import { Loading } from "../../Loading";
import { hasPermission } from "../../../services/auth";

ChartJS.register(ArcElement, Tooltip, Legend);


export const options = {
  plugins: {
    legend: {
      display: false
    }
  },
  maintainAspectRatio: false
}

export const BoxDoughnutChart = ({
  type,
  loading,
  getData,
  totalByStatus, 
  startChartDate,
  setStartChartDate,
  finalChartDate,
  setFinalChartDate,
  selectedChartStatuses,
  setSelectedChartStatuses,
  statuses
}) => {
    const [chartData, setChartData] = useState(null);

    useEffect(() => {
      getChartData();
    }, [totalByStatus]);

    const getChartData = () => {
      const toChartData = {
        legend: {
            display: false,
        },
        datasets: [
          {
            label: '',
            borderWidth: 0,
          },
        ],
      };

      let labels = [];
      let data = [];
      let backgroundColor = [];

      if(totalByStatus?.statuses){
        totalByStatus.statuses.map((total, index) => {
          labels[index] = total.status.name;
          data[index] = total.total_resources;
          backgroundColor[index] = total.status.color;
        });
      }

      toChartData[labels] = labels;
      toChartData.datasets[0].data = data;
      toChartData.datasets[0].backgroundColor = backgroundColor;
      
      setChartData(toChartData);
    }

    return (
        <Box>
          <div className="chart">
            {chartData !== null && !loading &&
              <Doughnut data={chartData} options={options} width={150} height={150} />
            }
            {loading &&
                <Loading show={true} />
            }
          </div>
          {!loading &&
            <table className="chart-table" style={{ marginTop: 50 }}>
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th align="left">Valor</th>
                  {type === 'order' &&
                    <>
                      {hasPermission('view_margem_order') &&
                        <>
                          <th align="left">Margem orçamento</th>
                          <th align="left">Margem real</th>
                        </>
                      }
                    </>
                  }
                </tr>
              </thead>
              {totalByStatus.statuses.map((total, index) => (
                <tr key={index}>
                  <td><DotStatus color={total.status.color} size={15} /> {total.status.name}</td>
                  <td>{total.total_resources}</td>
                  <td>R$ {convertIntToMoney(total.amount_resources)}</td>
                  {type === 'order' &&
                    <>
                      {hasPermission('view_margem_order') &&
                        <>
                          <td>R$ {convertIntToMoney(total.margem_orcamento)}</td>
                          <td>R$ {convertIntToMoney(total.margem_real)}</td>
                        </>
                      }
                    </>
                  }
                </tr>
              ))}
              <tr>
                <td></td>
                <td><b>{totalByStatus?.totals?.count}</b></td>
                <td><b>{totalByStatus?.totals ? `R$ ${convertIntToMoney(totalByStatus?.totals?.amount)}` : 'R$ 0,00'}</b></td>
                {type === 'order' &&
                  <>
                    {hasPermission('view_margem_order') &&
                      <>
                        <td><b>{totalByStatus?.totals ? `R$ ${convertIntToMoney(totalByStatus?.totals?.margem_orcamento)}` : 'R$ 0,00'}</b></td>
                        <td><b>{totalByStatus?.totals ? `R$ ${convertIntToMoney(totalByStatus?.totals?.margem_real)}` : 'R$ 0,00'}</b></td>
                      </>
                    }
                  </>
                }
              </tr>
            </table>
          }
        </Box>
    );
}

export default BoxDoughnutChart;