import React, { useEffect, useState } from "react";
import Select from 'react-select';

import PanelTemplate from "../../templates/Panel";
import Table from "../../components/Table";
import Box from "../../components/Box";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import PageHeader from "../../components/PageHeader";
import FilterSimple from "../../components/FilterSimple";
import DotStatus from "../../components/DotStatus";
import { useNavigate } from "react-router-dom";
import api from "../../services/api";
import { renderError } from "../../helpers/errors";
import moment from "moment";
import { createOptions } from "../../helpers/selects";
import { hasPermission } from "../../services/auth";
import { ModalDelete } from "../../components/ModalDelete";
import Pagination from "../../components/Pagination";
import { toast } from "react-toastify";

const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: '35px',
      height: '35px',
      borderTopLeftRadius: 0,
      borderBottomLeftRadius: 0,
      borderTopRightRadius: '3px',
      borderBottomRightRadius: '3px',
      boxShadow: state.isFocused ? null : null,
      fontSize: '14px',
      width: 250
    }),
}


export const ProductList = () => {
    const [loading, setLoading] = useState(false);
    const [filters, setFilters] = useState('');
    const [data, setData] = useState({header: [], rows: []});
    const [products, setProducts] = useState([]);

    const [categories, setCategories] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [currentPage, setCurrentPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [perPage, setPerPage] = useState(20);
    const [totalResults, setTotalResults] = useState(0);
    const [hasNextPage, setHasNextPage] = useState(false);
    const [hasPrevPage, setHasPrevPage] = useState(false);

    const [selectedIds, setSelectedIds] = useState([]);
    const [showConfirmMultipleDelete, setShowConfirmMultipleDelete] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        getCategories();
    }, []);

    useEffect(() => {
        getProducts();
    }, [currentPage,filters,selectedCategory]);

    useEffect(() => {
        generateData();
    }, [products]);

    const getProducts = () => {
        setProducts([]);
        setLoading(true);
        let categoryFilter = selectedCategory === null ? '' : `&filters[0][related][categories.id][equal]=${selectedCategory}`;
        api.get(`/product/paginate?${filters}${categoryFilter}&page=${currentPage}`).then(res => {
            setProducts(res.data.resources.data);
            setCurrentPage(res.data.resources.current_page);
            setLastPage(res.data.resources.last_page)
            setPerPage(res.data.resources.per_page)
            setTotalResults(res.data.resources.total)
            setHasNextPage(res.data.resources.next_page_url !== null);
            setHasPrevPage(res.data.resources.prev_page_url !== null);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getCategories = () => {
        api.get(`/category`).then(res => {
            setCategories(res.data.resources);
        });
    }

    const replicateProduct = id => {
        setLoading(true);

        api.post(`/product/replicate/${id}`).then(res => {
            toast.success('Produto duplicado com sucesso');
            getProducts();
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const generateData = () => {
        const header = ['Código', 'SKU', 'Imagem', 'Nome', 'Categoria', 'Última atualização', 'Cadastrado em', 'Status', 'Origem', ''];
        const rows = [];
        
        
        products.map(product => {
            let buttons = [];

            buttons.push(<Button type={`delete`} svg={`copy`} action={() => replicateProduct(product.id)} />);

            let image =  <img src={ require(`../../assets/images/svgs/default.svg`).default } />;
            if(product.image){
                image = <img src={product.image.image_url.small} />;
            }

            let categories = [];

            product.categories.map(category => categories.push(category.name));

            rows.push({
                data: [
                    product.code,
                    product.sku,
                    image,
                    product.name,
                    categories.join(', '),
                    moment(product.updated_at).format('DD/MM/YYYY HH:mm'),
                    moment(product.created_at).format('DD/MM/YYYY HH:mm'),
                    product.status ? 'Ativo' : 'Inativo',
                    product.origin === 'site' ? 'Site' : 'Integração'
                ],
                buttons,
                to: `/product/edit/${product.id}`,
                id: product.id
            })
        });

        setData({header, rows});
    }

    const deleteMultipleProducts = () => {
        setLoading(true);

        api.delete(`/product/multiple`, {
            data: {
                ids: selectedIds
            }
        }).then(res => {
            setSelectedIds([]);
            getProducts();
            setShowConfirmMultipleDelete(false);
        }).catch(error => {
            renderError(error);
            setLoading(false);
        });
    }

    const handleSetFilter = value => {
        setCurrentPage(1);
        setFilters(value);
    }
    

    return (
        <>
            <ModalDelete
                show={showConfirmMultipleDelete}
                text={<p>Tem certeza que deseja apagar <strong>{selectedIds.length} {selectedIds.length > 1 ? 'produtos' : 'produto'}</strong>?</p>}
                action={deleteMultipleProducts}
                loading={loading}
                close={() => setShowConfirmMultipleDelete(false)}
            />
            <PanelTemplate id="product-list">
                <PageHeader
                    left={
                        <>
                            <FilterSimple 
                                params={[
                                    {name: 'Nome', key: '[name]', type: 'text' },
                                    {name: 'SKU', key: '[sku]', type: 'text' },
                                    {name: 'Código', key: '[code]', type: 'text' },
                                    {name: 'Origem', key: '[origin]', type: 'text' }
                                ]}
                                filter={handleSetFilter}
                            />
                            <Select
                                placeholder="Escolha uma categoria..."
                                options={[{label: 'Todas as categorias', value: null}, ...createOptions(categories, 'name', 'id')]}
                                onChange={e => setSelectedCategory(e.value)}
                                value={selectedCategory !== null ? { label: categories.filter(c => c.id === selectedCategory)[0].name, value: selectedCategory} : null}
                                styles={customStyles}
                            />
                        </>
                    }

                    right={
                        <>
                            {hasPermission('delete_product') && selectedIds.length > 0 &&
                                <Button 
                                type={`secondary`} 
                                size={`small`} 
                                svg={`delete`}
                                text={`Excluir ${selectedIds.length} selecionados`} 
                                action={() => setShowConfirmMultipleDelete(true)}  
                                />
                            }
                            {hasPermission('create_product') &&
                                <Button type={`primary`} size={`small`} text={`Cadastrar produto`} svg="plus-white" link={`/product/create`}  />
                            }
                            {/* <Button type={`secondary`} size={`small`} text={`Filtrar`} svg="filter"  /> */}
                        </>
                    }
                />
                
                <Box className={`no-padding with-pagination`}>
                    <Table 
                        header={data.header} 
                        rows={data.rows} 
                        loading={loading}
                        checks={true} 
                        selectedIds={selectedIds}
                        setSelectedIds={setSelectedIds}
                    />
                </Box>
                {!loading &&
                    <Pagination
                        currentPage={currentPage}
                        lastPage={lastPage}
                        perPage={perPage}
                        totalResults={totalResults}
                        hasNextPage={hasNextPage}
                        hasPrevPage={hasPrevPage}
                        setCurrentPage={setCurrentPage}
                    />
                }
            </PanelTemplate>
        </>
    );
}

export default ProductList;