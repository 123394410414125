import { convertMoenyToInt, convertPercentageToFloat } from "../../../helpers/conversions";

export class Calculation {
    constructor(formulaSalePrice, formulaCommission, productQuantity, productUnitCost, productCustomizations, productParams, taxPercentage, params) {
        this.formulaSalePrice = formulaSalePrice;
        this.formulaCommission = formulaCommission;
        this.productQuantity = productQuantity;
        this.productUnitCost = productUnitCost;
        this.productCustomizations = productCustomizations;
        this.productParams = productParams;
        this.taxPercentage = taxPercentage;
        this.params = params;
    }

    getCommission = () => {
        let price = this.getSalePrice() / 100;
        price = price.toFixed(2);
        let formula = this.formulaCommission;
        formula = this.replaceFormulaParams(formula);

        formula = formula.replaceAll('[Preço de venda]', price);

        try {
            let result = eval(formula) * 100;
            return result;
        } catch (error) {
            return 0;
        }
    }

    getSalePrice = () => {
        let formula = this.formulaSalePrice;
        formula = this.replaceFormulaParams(formula);

        try {
            let result = eval(formula) * 100;
            return result;
        } catch (error) {
            return 0;
        }
    }

    replaceFormulaParams = formula => {
        let quantidade = this.productQuantity;
        let custo = convertMoenyToInt(this.productUnitCost) / 100;
        let gravacao = this.getCustomizationPrice() / 100;
        let customParams = this.getCustomParams();
        let imposto = this.taxPercentage / 1000000;

        formula = formula.replaceAll('[Quantidade]', quantidade);
        formula = formula.replaceAll('[Custo unitário]', custo);
        formula = formula.replaceAll('[Gravação]', gravacao);
        formula = formula.replaceAll('[Imposto]', imposto);

        customParams.map(customParam => {
            formula = formula.replaceAll(`[${customParam.name}]`, customParam.value);
        });

        return formula;
    }

    getCustomizationPrice = () => {
        let result = 0;
        this.productCustomizations.map(productCustomization => {
            result += convertMoenyToInt(productCustomization.customizationTotalCost);
        });
        return result;
    }

    getCustomParams = () => {
        let params = [];

        this.productParams.map(productParam => {
            let param = this.getParam(productParam);
            if(param === null) return;

            let value = null;
            switch (param.type) {
                case 'quantity_and_price':
                    value = this.getQuantityAndPriceValue(productParam) / 100;
                    break;
                case 'multiple_value':
                    value = this.getMultipleValueValue(productParam) / 100;
                    break;
                case 'multiple_value_with_quantity':
                    value = this.getMultipleValueWithQuantityValue(productParam, param) / 100;
                    break;

                case 'percentage':
                    value = this.getPercentage(productParam);
                    break;

                case 'multiple_percentage':
                    value = this.getPercentage(productParam);
                    break;

                case 'extra':
                    value = this.getExtra(productParam) / 100;
                    break;

                case 'conditional_percentage':
                    value = this.getConditionalPercentage(param);
                    break;
            
                default:
                    break;
            }

            params.push({
                id: param.id,
                name: param.name,
                value
            });
        });
        return params;
    }

    getParam = productParam => {
        let currentParam = this.params.filter(param => param.id === productParam.id);
        return currentParam[0] ?? null;
    }

    getQuantityAndPriceValue = productParam => {
        let quantity = productParam.values[0] ?? 0;
        let value = productParam.values[1] ? convertMoenyToInt(productParam.values[1]) : 0;
        return quantity * value;
    }

    getMultipleValueValue = productParam => {
        return productParam.value ? convertMoenyToInt(productParam.value) : 0;
    }

    getMultipleValueValue = productParam => {
        return productParam.value ? convertMoenyToInt(productParam.value) : 0;
    }

    getMultipleValueWithQuantityValue = (productParam, param) => {
        let value = 0;
        param.options.map((option, index) => {
            if(productParam.values[index]){
                let optionValue = convertMoenyToInt(option.value);
                let quantity = productParam.values[index];
                value += optionValue * quantity;
            }
        });

        return value;
    }

    getPercentage = productParam => {
        let value = productParam.value ? convertPercentageToFloat(productParam.value) : 0;
        return value;
    }

    getExtra = productParam => {
        let result = 0;
        productParam.values.map(value => {
            result += convertMoenyToInt(value);
        });
        return result;
    }

    getConditionalPercentage = param => {
        let result = 0;
        let productParamParents = this.productParams.filter(productParam => productParam.id === param.param_id);
        let productParamParent = productParamParents[0];

        if(productParamParent === undefined) return 0;

        param.options.map(option => {
            if(option.related_percentage === productParamParent.value){
                result = convertPercentageToFloat(option.value);
            }
        });

        return result;
    }
}