import moment from "moment";
import React, { useState } from "react";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import Input from "../../../components/Input";
import api from "../../../services/api";
import { renderError } from "../../../helpers/errors";
import { toast } from "react-toastify";

export const ModalUnirOrcamentos = ({quoteId, setShowUnirOrcamentos, getQuote}) => {
    const [code, setCode] = useState('');

    const unir = () => {
        api.post(`/budget/join/${quoteId}`, {
            code
        }).then(res => {
            toast.success('União realizada com sucesso');
            getQuote();
            setShowUnirOrcamentos(false);
        }).catch(error => {
            renderError(error);
        });
    }

    return (
        <Modal
            show={true}
            close={() => setShowUnirOrcamentos(false)}
            title={`Unir orçamentos`}
            footer={
                <>
                    <Button
                        type={`primary`}
                        size={`small`}
                        text={`Unir orçamentos`}
                        action={unir}
                    />
                    
                </>
            }
        >
            <div className="section">
                <p style={{ marginBottom: 30 }}>Ao realizar a união, o orçamento informado abaixo deixará de existir e os produtos dele virão para esse que está em edição.</p>
                <Input value={code} change={setCode} label={`Código do orçamento`} style={{ width: 200 }} />
            </div>
        </Modal>
    )
}