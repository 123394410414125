import axios from "axios";
import React, { useEffect, useState } from "react";
import { renderError } from "../../../helpers/errors";
import { createFilterQueryString } from "../../../helpers/helpers";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";
import InputSelect from "../../InputSelect";

export const SelectCustomer = ({selectedCustomer, setSelectedCustomer, companyId, relatedWithCompany, setCustomerData}) => {
    const [customers, setCustomers] = useState([]);
    const [search, setSearch] = useState('');
    const [noOptionsText, setNoOptionsText ] = useState(null);

    useEffect(() => {
        getCustomers();
    }, [search]);

    useEffect(() => {
        getSelectedCustomer();
    }, [selectedCustomer])

    useEffect(() => {
        setSearch('');
        getCustomers();
    }, [companyId, relatedWithCompany]);

    const getSelectedCustomer = () => {
        if(selectedCustomer === null) return;
        api.get(`/customer/${selectedCustomer}`).then(res => {
            setCustomers([res.data.resource]);
            if(setCustomerData){
                setCustomerData(res.data.resource);
            }
        });
    }

    const getCustomers = () => {
        setCustomers([]);

        let minSearch = 1;

        if(relatedWithCompany && companyId === null){
            setNoOptionsText(`Primeiro, escolha a empresa...`);
            return;
        } else if (relatedWithCompany && companyId !== null){
            setNoOptionsText('Carregando...');
        } else if (search.length < minSearch && !relatedWithCompany) {
            setNoOptionsText(`Digite ao menos ${minSearch - search.length} caracter...`);
            return;
        } else {
            setNoOptionsText('Carregando...');
        }

        if (typeof cancelToken != typeof undefined) {
            window.cancelToken.cancel()
        }

        window.cancelToken = axios.CancelToken.source();

        let arrayFilters  = [];

        if(companyId !== null){
            arrayFilters = [
                `[related][companies.id][equal]=${companyId}`
            ];
        } else {
            arrayFilters = [
                [
                    // '[type][contain]=customer',
                    `[code][equal]=${search}`,
                ],
                [
                    // '[type][contain]=customer',
                    `[name][contain]=${search}`,
                ]
            ];
        }

        let stringFilters = createFilterQueryString(arrayFilters);
        api.get(`/customer?${stringFilters}`, {
            cancelToken: window.cancelToken.token
        }).then(res => {
            setCustomers(res.data.resources);
            if(res.data.resources.length === 0){
                setNoOptionsText(null);
            }
        }).catch(error => {
            if(error.code !== undefined){
                renderError(error);
            }
        });
    }

    return (
        <InputSelect
            noOptionsText={noOptionsText}
            label={`Pessoa`}
            options={createOptions(customers, ['code', 'name'], 'id')} 
            value={selectedCustomer} 
            change={setSelectedCustomer}
            setSearch={value => setSearch(value)}
        />
    );
}

export default SelectCustomer;