import React, { useEffect, useState } from "react";

import InputSelect from "../../../components/InputSelect";
import Input from "../../../components/Input";
import InputRadio from "../../../components/InputRadio";
import Button from "../../../components/Button";
import Modal from "../../../components/Modal";
import { renderError } from "../../../helpers/errors";
import api from "../../../services/api";
import { createOptions, createProductOptions } from '../../../helpers/selects';
import { convertIntToMoney, convertMoenyToInt } from "../../../helpers/conversions";
import CustomizationSection from "./CustomizationSection";
import ParamSection from "./ParamSection";
import { Calculation } from "./calculationPrice";
import { calculateCustomizationTotal } from "./customizationCalculate";
import { ModalDelete } from "../../../components/ModalDelete";
import { toast } from "react-toastify";
import SelectBillingCompany from "../../../components/selects/SelectBillingCompany";
import SelectProduct from "../../../components/selects/SelectProduct";

const calculationTypeOptions = [
    { label: 'Cálculo automático', value: 'automatic' },
    { label: 'Cálculo manual', value: 'manual' }
];

const ModalProduct = ({close, quote, itemToEdit, selectedBillingCompany, setSelectedBillingCompany, getQuote, updateQuote}) => {
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState([]);
    const [customizations, setCustomizations] = useState([]);
    const [products, setProducts] = useState([]);
    const [subproducts, setSubproducts] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSubproduct, setSelectedSubproduct] = useState(null);

    const [companiesProduct, setCompaniesProduct] = useState([]);
    const [selectedCompanyProduct, setSelectedCompanyProduct] = useState(null);

    const [productCalculationType, setProductCalculationType] = useState('manual');
    const [productQuantity, setProductQuantity] = useState(0);
    const [productQuantityColors, setProductQuantityColors] = useState(0);
    const [productUnitCost, setProductUnitCost] = useState('0,00');
    const [productUnitCostError, setProductUnitCostError] = useState('');
    const [productTotalCost, setProductTotalCost] = useState('0,00');

    const [productCustomizations, setProductCustomizations] = useState([]);
    const [productParams, setProductParams] = useState([]);

    const [formulaPrice, setFormulaPrice] = useState('');
    const [formulaCommission, setFormulaCommission] = useState('');

    const [salePrice, setSalePrice] = useState('R$ 0,00');
    const [totalSalePrice, setTotalSalePrice] = useState('R$ 0,00');
    const [commission, setCommission] = useState('R$ 0,00');

    const [gettingBillingCompanies, setGettingBillingCompanies] = useState(true);
    const [billingCompanies, setBillingCompanies] = useState([]);

    const [showDelete, setShowDelete] = useState(false);

    useEffect(() => {
        getProduct();
        getParams();
        getCustomizations();
        getCompaniesProduct();
        getBillingCompanies();
    }, []);

    useEffect(() => {
        getSubproducts();
    }, [products, selectedProduct]);

    
    useEffect(() => {
        getCompaniesProduct();
    }, [products, selectedSubproduct])

    const getBillingCompanies = () => {
        setGettingBillingCompanies(true);

        api.get(`/company?filters[0][type][contain]=billing`).then(res => {
            setBillingCompanies(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setGettingBillingCompanies(false));
    }
    
    const handleProductChange = productId => {
        setSelectedSubproduct(null);
        setCompaniesProduct([]);
        setSelectedCompanyProduct(null);
        setSelectedProduct(productId);
    }

    const getSubproducts = () => {
        let toSubproducts = [];
        let product = products.filter(product => product.id === selectedProduct)[0];
        if(!product) return;
        product.subproducts.map(subproduct => {
            toSubproducts.push({
                id: subproduct.id,
                desc: `${subproduct.color.name} - ${subproduct.sku}`
            });
        });
        setSubproducts(toSubproducts);
    }

    useEffect(() => {
        calculateProductTotalCost();
    }, [productQuantity, productUnitCost]);

    useEffect(() => {
        getProductUnitCost();
    }, [selectedCompanyProduct, productQuantity, productCalculationType, selectedSubproduct, selectedProduct, products]);

    useEffect(() => {
        calculatePrice();
    }, [billingCompanies, selectedBillingCompany, productQuantity, productUnitCost, productCustomizations, productParams]);

    useEffect(() => {
        getTotalSalePrice();
    }, [productQuantity, salePrice]);

    useEffect(() => {
        getProductParams();
    }, [params])

    const getProduct = () => {
        if(!itemToEdit) return;
        setSelectedProduct(itemToEdit.subproduct.product_id);
        setSelectedSubproduct(itemToEdit.subproduct_id);
        setSelectedCompanyProduct(itemToEdit.company_id);
        setProductCalculationType(itemToEdit.calculation_type);
        setProductQuantity(itemToEdit.quantity);
        setProductQuantityColors(itemToEdit.quantity_colors);
        setProductUnitCost( `R$ ${convertIntToMoney(itemToEdit.cost)}` );

        getProductCustomizations(itemToEdit.budget_item_customizations);
    }

    const getTotalSalePrice = () => {
        let quantity = productQuantity;
        let price = convertMoenyToInt(salePrice);
        let total = price * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setTotalSalePrice(total);
    }

    const calculatePrice = () => {
        if(billingCompanies.length === 0){
            return;
        }
        const taxPercentage = getTaxPercentage();
        const calculation = new Calculation(
            formulaPrice, 
            formulaCommission, 
            productQuantity, 
            productUnitCost, 
            productCustomizations, 
            productParams,
            taxPercentage,
            params
        );

        const toSalePrice = calculation.getSalePrice();
        const toCommission = calculation.getCommission();

        setSalePrice( `R$ ${convertIntToMoney(toSalePrice)}`);
        setCommission( `R$ ${convertIntToMoney(toCommission)}`);
    }

    const getTaxPercentage = () => {
        const company = billingCompanies.filter(billingCompany => billingCompany.id === selectedBillingCompany)[0];
        return company.tax_percentage;
    }

    const addProductCustomization = () => {
        const toProductCustomization = {
            calculationType: 'automatic',
            selectedCustomization: null,
            selectedCompanyCustomization: null,
            quantityColors: productQuantityColors,
            quantity: productQuantity,
            customizationUnitCost: `R$ 0,00`,
            customizationTotalCost: `R$ 0,00`
        };

        setProductCustomizations(prev => [...prev, toProductCustomization]);
    }

    const getCompaniesProduct = () => {
        let toCompaniesProduct = [];
        let prices = getPrices();

        prices.map(price => {
            toCompaniesProduct.push(price.company);
        });
        setCompaniesProduct(toCompaniesProduct);
    }

    const getPrices = () => {
        let prices = [];
        let product = products.filter(product => product.id === selectedProduct);
        if(product.length > 0){
            product = product[0];
            let subproduct = product.subproducts.filter(subproduct => subproduct.id === selectedSubproduct);
            if(subproduct.length > 0){
                subproduct = subproduct[0];
                subproduct.prices.map(price => {
                    prices.push(price);
                });
            }
        }
        return prices;
    }
    
    const getParams = () => {
        api.get('/param').then(res => {
            let toParams = [];
            res.data.resources.map(resource => {
                if(resource.type === 'price_calculation'){
                    setFormulaPrice(resource.value);
                    return;
                }
                if(resource.type === 'commission_calculation'){
                    setFormulaCommission(resource.value);
                    return;
                }
                toParams.push(resource);
            })
            setParams(toParams);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const getProductCustomizations = (currentProductCustomizations) => {
        let toProductCustomizations = [];
        currentProductCustomizations.map(current => {
            let total = calculateCustomizationTotal(
                current.quantity,
                current.cost,
                current.customization.calculation_per
            );
            toProductCustomizations.push({
                calculationType: current.calculation_type,
                selectedCustomization: current.customization_id,
                selectedSupplierCustomization: current.company_id ? current.company_id : current.customer_id,
                selectedSupplierTypeCustomization: current.company_id ? "PJ" : "PF",
                quantityColors: current.quantity_colors,
                quantity: current.quantity,
                customizationUnitCost: `R$ ${convertIntToMoney( current.cost )}`,
                customizationTotalCost: `R$ ${convertIntToMoney( total )}`
            });
        });

        setProductCustomizations(toProductCustomizations);
    }

    const getProductParams = () => {
        let toProductParams = [];
        let currentParams = itemToEdit?.params ? JSON.parse( itemToEdit.params ) : [];
        params.map(param => {
            let currentParam = currentParams.filter(currentParam => currentParam.id === param.id);
            if(currentParam.length === 0){
                toProductParams.push({
                    type: param.type,
                    name: param.name,
                    options: param.options,
                    position: param.position,
                    param_id: param.param_id,
                    id: param.id,
                    formula_total_real: param.formula_total_real,
                    formula_total_quote: param.formula_total_quote,
                    value: null,
                    values: []
                });
            } else {
                toProductParams.push(currentParam[0]);
            }
        });

        setProductParams(toProductParams);
    }

    const getCustomizations = () => {
        api.get('/customization').then(res => {
            setCustomizations(res.data.resources);
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }
    
    const calculateProductTotalCost = () => {
        let quantity = productQuantity;
        let cost = productUnitCost;
        cost = convertMoenyToInt(cost);
        let total = cost * quantity;
        total = `R$ ${convertIntToMoney(total)}`;
        setProductTotalCost(total);
    }

    const getProductUnitCost = () => {
        setProductUnitCostError('');

        if(productCalculationType === 'manual') return;

        const companyPrice = getPrices().filter(price => price.company.id === selectedCompanyProduct);
        const quantity = productQuantity;
        let prices = [];
        let error = 'Preço do fornecedor não encontrado.';
        let cost = 0;

        if(companyPrice.length > 0){
            if(companyPrice[0].prices.length > 0){
                prices = companyPrice[0].prices;
            }
        }

        prices.map(price => {
            if(price.init_qtd <= quantity && price.final_qtd >= quantity){
                error = '';
                cost = price.price;
            }
        });

        setProductUnitCost( `R$ ${convertIntToMoney(cost)}` );
        setProductUnitCostError(error);
    }

    const updateProduct = (create = false) => {
        const validation = validate();
        if(!validation) return;

        setLoading(true);

        let itemToStore = {
            budget_id: quote.id,
            subproduct_id: selectedSubproduct,
            company_id: selectedCompanyProduct,
            quantity: productQuantity,
            calculation_type: productCalculationType,
            quantity_colors: 0,
            cost: convertMoenyToInt( productUnitCost ),
            price: convertMoenyToInt( salePrice ),
            params: JSON.stringify( productParams ),
            customizations: getCustomizationsToStore()
        };
        
        api({
            method: create ? 'post' : 'put',
            url: create ? `/budget/item` : `/budget/item/${itemToEdit.id}`,
            data: itemToStore
        }).then(res => {
            updateQuote();
            if(create){
                toast.success('Produto adicionado com sucesso'); 
            } else {
                close();
            }
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    const validate = () => {
        let result = true;
        productParams.map(productParam => {
            if(productParam.value === null){
                let param = params.filter(param => param.id === productParam.id)[0];
                if(param.required){
                    toast.error(`O campo ${param.name} precisa ser informado`);
                    result = false;
                }
            }
        })

        return result;
    }

    const getCustomizationsToStore = () => {
        let customizationsToStore = [];
        productCustomizations.map(productCustomization => {
            customizationsToStore.push({
                customization_id: productCustomization.selectedCustomization,
                company_id: productCustomization.selectedSupplierTypeCustomization === "PJ" ? productCustomization.selectedSupplierCustomization : null,
                customer_id: productCustomization.selectedSupplierTypeCustomization === "PF" ? productCustomization.selectedSupplierCustomization : null,
                quantity_colors: productCustomization.quantityColors,
                quantity: productCustomization.quantity,
                cost: convertMoenyToInt( productCustomization.customizationUnitCost ),
                total_cost: convertMoenyToInt( productCustomization.customizationTotalCost ),
                calculation_type: productCustomization.calculationType
            });
        });
        return customizationsToStore;
    }

    const deleteProduct = () => {
        api.delete(`/budget/item/${itemToEdit.id}`).then(res => {
            getQuote();
            close();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <>
            <Modal
                title={`Configuração do produto`}
                size={`big`}
                show={true}
                close={close}
                footer={
                    <>
                        <Input label={`Preço unit.`} value={salePrice} disabled={true} />
                        <Input label={`Total`} value={totalSalePrice} disabled={true} />
                        <Input label={`Comissão`} value={commission} disabled={true} />
                        <Button type={`secondary`} svg={`plus-blue`} text={`Adicionar`} loading={loading} action={() => updateProduct(true)} />
                        {itemToEdit &&
                            <Button type={`primary`}  svg={`save-white`} loading={loading} text={`Atualizar`} action={() => updateProduct()} />
                        }
                    </>
                }
            >
                {gettingBillingCompanies && 
                    <p>Carregando...</p>
                }
                {!gettingBillingCompanies && billingCompanies.length === 0 &&
                    <p>Nenhuma empresa de faturamento encontrada</p>
                }
                {!gettingBillingCompanies && billingCompanies.length > 0 &&
                <>
                    <div className="section">
                        <SelectBillingCompany
                            selectedBillingCompany={selectedBillingCompany}
                            setSelectedBillingCompany={setSelectedBillingCompany}
                        />
                    </div>
                    <div className="modal-section-title">
                        <div className="left">
                            <h2>Produto</h2>
                        </div>
                        <div className="right">
                            <InputRadio
                                className={`no-margin`}
                                options={calculationTypeOptions}
                                value={productCalculationType}
                                change={setProductCalculationType}
                            />
                        </div>
                    </div>
                    <div className="section">
                        <div className="row">
                            <div className="col-4">
                                <SelectProduct
                                    changeProducts={setProducts}
                                    selectedProduct={selectedProduct}
                                    setSelectedProduct={handleProductChange}
                                />
                                {selectedProduct &&
                                    <a href={`/product/edit/${selectedProduct}`} className="link-product-details" target="_blank">Mais detalhes</a>
                                }
                            </div>
                            <div className="col-4">
                                <InputSelect 
                                    label={`Cor`}
                                    options={createOptions(subproducts, 'desc', 'id')} 
                                    value={selectedSubproduct} 
                                    change={setSelectedSubproduct}
                                />
                            </div>
                            <div className="col-4">
                                <InputSelect
                                    label={`Fornecedor`}
                                    options={createOptions(companiesProduct, 'trade_name', 'id', true)}
                                    
                                    value={selectedCompanyProduct}
                                    change={setSelectedCompanyProduct}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-4">
                                <Input 
                                    label={`Quantidade`}
                                    value={productQuantity}
                                    change={setProductQuantity}
                                    mask={`convertToInt`}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    label={`Custo unit.`}
                                    value={productUnitCost}
                                    change={setProductUnitCost}
                                    mask={`convertToMoney`}
                                    disabled={productCalculationType === 'automatic'}
                                    error={productUnitCostError}
                                />
                            </div>
                            <div className="col-4">
                                <Input
                                    label={`Custo total`}
                                    value={productTotalCost}
                                    disabled={true}
                                />
                            </div>
                        </div>
                    </div>
                    
                    {customizations.length > 0 && productCustomizations.map((productCustomization, index) => (

                        <CustomizationSection
                            key={index}
                            index={index}
                            calculationTypeOptions={calculationTypeOptions}
                            customizations={customizations}
                            productCustomization={productCustomization}
                            productCustomizations={productCustomizations}
                            setProductCustomizations={setProductCustomizations}
                            productQuantity={productQuantity}
                        />
                    ))}

                    {customizations.length > 0 &&
                        <Button 
                            type={`link`}
                            size={`small`}
                            full={true}
                            text={`Adicionar gravação`}
                            action={addProductCustomization}
                        />
                    }

                    {params.map((param, index) => (
                        <ParamSection
                            key={index}
                            param={param}
                            productParams={productParams}
                            setProductParams={setProductParams}
                        />
                    ))}
                </>
                }
            </Modal>
            <ModalDelete
                action={deleteProduct}
                close={() => setShowDelete(false)}
                show={showDelete}
                loading={loading}
            />
        </>
    );
}

export default ModalProduct;