import React from "react";
import * as Masks from "../../helpers/masks";

export const Input = ({label, error, placeholder, type, change, value, disabled, left, right, mask, action, autoComplete, inputRef, style}) => {
    const applyMask = value => {
        if(mask){
            return Masks[mask](value);
        }

        return value;
    }

    const onKeyUp = event => {
        if (event.charCode === 13) {
            if(action !== undefined){
                action();
            }
        }
    }

    return (
        <div className={`input-group${left ? ' left' : ''}${error ? ' has-error' : ''}`} style={style}>
            {label &&
                <label>{label}</label>
            }
            {left &&
                <div className="left">
                    {left}
                </div>
            }
            {right &&
                <div className="right">
                    {right}
                </div>
            }
            <input 
                ref={inputRef}
                type={type ?? 'text'}
                disabled={disabled ? true : false}
                placeholder={placeholder ?? ''}
                onChange={e => change( applyMask(e.target.value))}
                value={value ?? ''}
                onKeyPress={onKeyUp}
                autoComplete={autoComplete ?? 'off'}
            />
            {error &&
                <span className="error-message">{error}</span>
            }
        </div>
    );
}

export default Input;