import React from "react";

export const Box = ({className, title, tools, children}) => {
    return (
        <div className={`box ${className ?? ''}`}>
            {(title || tools) &&
                <div className="header">
                    <span>{title}</span>
                    <div className="tools">
                        {tools}
                    </div>
                </div>
            }
            <div className="body">
                {children}
            </div>
        </div>
    );
}

export default Box;