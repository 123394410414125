import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import AuthTemplate from "../../templates/Auth";
import api from "../../services/api";

import Input from "../../components/Input";
import InputPassword from "../../components/InputPassword";
import Button from "../../components/Button";
import { logout, setToken, setUser } from "../../services/auth";
import { renderError } from "../../helpers/errors";
import { getInitialPath } from "../../routes/privateRoutes";

export const Login = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    useEffect(() => {
        logout();
    }, []);

    const signIn = () => {
        setLoading(true);

        api.post(`/login`, { email, password }).then(res => {
            setToken(res.data.token);
            setUser(res.data.admin);
            window.location.href="/";
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <AuthTemplate>
            <img id="logo" src={ require(`../../assets/images/svgs/logo.svg`).default} />
            <Input type={`email`} label={`E-mail`} change={setEmail} value={email} action={signIn} />
            <Input type={`password`} label={`Senha`} change={setPassword} value={password} action={signIn} />
            <Button type={`primary`} text={`Entrar`} full={true} svg={`arrow-right-white`} loading={loading} action={signIn} />
        </AuthTemplate>
    );
}

export default Login;