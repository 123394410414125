import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../components/Button";
import Input from "../../../components/Input";
import InputSelect from "../../../components/InputSelect";
import Modal from "../../../components/Modal";
import SelectBillingCompany from "../../../components/selects/SelectBillingCompany";
import SelectCustomer from "../../../components/selects/SelectCustomer";
import SelectCustomerCompany from "../../../components/selects/SelectCustomerCompany";
import { renderError } from "../../../helpers/errors";
import { createOptions } from "../../../helpers/selects";
import api from "../../../services/api";

const budgetTypes = [
    {label: 'Pessoa física', value: 'PF'},
    {label: 'Pessoa jurídica', value: 'PJ'}
];

const ModalCreateQuote = ({finishCreation, close, customerCompanyId}) => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);
    const [billingCompanies, setBillingCompanies] = useState([]);
    const [customerCompanies, setCustomerCompanies] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [allCustomers, setAllCustomers] = useState([]);

    const [selectedBudgetType, setSelectedBudgetType] = useState(null);
    const [selectedBillingCompany, setSelectedBillingCompany] = useState(null);
    const [selectedCustomerCompany, setSelectedCustomerCompany] = useState(null);
    const [selectedCustomer, setSelectedCustomer] = useState(null);
    const [origin, setOrigin] = useState('');

    useEffect(() => {
        getCompanies();
        getAllCustomers();
    }, []);

    useEffect(() => {
        getOrigin();
        getCustomers();
    }, [selectedCustomerCompany, customerCompanies, selectedBudgetType]);

    useEffect(() => {
        setSelectedCustomer(null);
        setSelectedCustomerCompany(null);
    }, [selectedBudgetType])

    useEffect(() => {
        setSelectedCustomerCompany(customerCompanyId);
    }, [customerCompanyId]);

    const getCompanies = () => {
        api.get(`/company`).then(res => {
            let toBillingCompanies = [];
            let toCustomerCompanies = [];

            res.data.resources.map(resource => {
                if(resource.type.includes('billing')){
                    toBillingCompanies.push(resource);
                }
                if(resource.type.includes('customer')){
                    toCustomerCompanies.push(resource);
                }
            });

            setBillingCompanies(toBillingCompanies);
            setCustomerCompanies(toCustomerCompanies);
        });
    }

    const getAllCustomers = () => {
        api.get(`/customer`).then(res => {
            setAllCustomers(res.data.resources);
        });
    }

    const getOrigin = () => {
        let company = customerCompanies.filter(customerCompany => customerCompany.id === selectedCustomerCompany)[0];
        if(company){
            setOrigin(company.origin);
        } else {
            setOrigin('');
        }
    }

    const getCustomers = () => {
        if(selectedBudgetType === "PF"){
            setCustomers(allCustomers);
        }
        if(selectedBudgetType === "PJ"){
            let company = customerCompanies.filter(customerCompany => customerCompany.id === selectedCustomerCompany)[0];
            if(company) setCustomers(company.customers);
        }
    }

    const storeQuote = () => {
        setLoading(true);

        api.post(`/budget`, {
            type: selectedBudgetType,
            billing_company_id: selectedBillingCompany,
            company_id: selectedCustomerCompany,
            customer_id: selectedCustomer,
            comments: null,
            items: []
        }).then(res => {
            navigate(`/quote/edit/${res.data.resource.id}`);
            // finishCreation();
            // close();
        }).catch(error => {
            renderError(error);
        }).then(() => setLoading(false));
    }

    return (
        <Modal
            size={`big`}
            title={`Cadastrar orçamento`}
            show={true}
            close={close}
            footer={
                <>
                    <Button type={`primary`}  svg={`save-white`} loading={loading} text={`Cadastrar orçamento`} action={() => storeQuote()} />
                </>
            }
        >
            <div className="modal-section-title">
                <div className="left">
                    <h2>Dados do orçamento</h2>
                </div>
            </div>
            <div className="section">
                <div className="row">
                    <div className="col-6">
                        <SelectBillingCompany 
                            selectedBillingCompany={selectedBillingCompany} 
                            setSelectedBillingCompany={setSelectedBillingCompany}
                        />
                    </div>
                    <div className="col-6">
                        <InputSelect 
                            label={`Tipo do cliente`}
                            options={budgetTypes} 
                            value={selectedBudgetType} 
                            change={setSelectedBudgetType}
                        />
                    </div>
                </div>
                {selectedBudgetType !== null &&
                    <div className="row">
                        {selectedBudgetType === "PJ" &&
                            <div className="col-6">
                                <SelectCustomerCompany
                                    selectedCustomerCompany={selectedCustomerCompany}
                                    setSelectedCustomerCompany={setSelectedCustomerCompany}
                                />
                            </div>
                        }
                        <div className="col-6">
                            <SelectCustomer
                                selectedCustomer={selectedCustomer}
                                setSelectedCustomer={setSelectedCustomer}
                                companyId={selectedBudgetType === "PJ" ? selectedCustomerCompany : null}
                                relatedWithCompany={selectedBudgetType === "PJ"}
                            />
                        </div>
                    </div>
                }
            </div>
        </Modal>
    )
}
export default ModalCreateQuote;